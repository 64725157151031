import cn from 'classnames';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import hoverActionsStyles from '@/Framework/UI/Molecules/Tables/HoverActions/hoverActions.scss';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import PreviewButtonHandler
  from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/PreviewButton/PreviewButtonHandler';
import { SidebarMode } from '@/Framework/UI/Organisms/DocumentViewer/SidebarMode';
import IconRedaction from '@dealroadshow/uikit/core/components/Icon/IconRedaction';

interface IProps {
  item: IFilesystemListItem,
  variant?: string,
  className?: string,
  isDisabled?: boolean,
}

const RedactFileButton = ({
  item,
  variant = variantTypes.icon,
  className,
  isDisabled,
}: IProps) => (
  <PreviewButtonHandler item={ item } mode={ SidebarMode.Redaction } search={ '' } isRedactionModeEnabled>
    { ({ onClick }) => (
      <ActionButton
        className={ cn(className, {
          [hoverActionsStyles.textActionItem]: variant === variantTypes.text,
        }) }
        title="Redact"
        onClick={ onClick }
        variant={ variant }
        icon={ IconRedaction }
        isDisabled={ isDisabled }
        dataTest="redactFileButton"
      />
    ) }
  </PreviewButtonHandler>
);

export default RedactFileButton;
