import { createContext, useContext, ReactNode, useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';

type Items = Pick<IFilesystemListItem, 'id' | 'type'>[];

const getIdsAndTypes = (items: Items) => items.map(({
  id,
  type,
}) => ({
  id,
  type,
}));

const useRedactByKeyword = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState(false);
  const [canRedact, setCanRedact] = useState(false);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  const checkFilesForRedactByKeyword = async (items: Items) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        items: getIdsAndTypes(items),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      const { validation } = await redactionRepository.checkFilesForRedactByKeyword(payload);
      setCanRedact(validation);
    } catch (error) {
      if (error instanceof RpcError) {
        if (error?.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_REDACTION_SEARCH_BY_KEYWORD_LIMIT_EXCEEDED')) {
          setIsLimitExceeded(true);
          return;
        }
        if (error?.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_REDACTION_NO_SCANNABLE_FILES_SELECTED')) {
          NotificationManager.error(getMessage(messageCodes.DATAROOM_REDACTION_NO_SCANNABLE_FILES_SELECTED));
          return;
        }
      }
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const scanByKeyword = async (search: string, items: Items) => {
    try {
      const payload = {
        dataroomId: dataroom.id,
        search,
        items: getIdsAndTypes(items),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      // TODO: need to remove this message after socket implementation
      NotificationManager.success(getMessage(messageCodes.DATAROOM_REDACTION_SCAN_BY_KEYWORD_STARTED));
      await redactionRepository.scanByKeyword(payload);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    }
  };

  return {
    isFetching,
    canRedact,
    isLimitExceeded,
    setIsLimitExceeded,
    setCanRedact,
    scanByKeyword,
    checkFilesForRedactByKeyword,
  };
};
export const RedactByKeywordContext = createContext<ReturnType<typeof useRedactByKeyword>>(null);

export const useRedactByKeywordContext = () => {
  const context = useContext(RedactByKeywordContext);
  if (!context) {
    throw new Error('useRedactByKeywordContext must be used within a RedactByKeywordContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const RedactByKeywordContextProvider = ({ children }: IProps) => (
  <RedactByKeywordContext.Provider value={ useRedactByKeyword() }>
    { children }
  </RedactByKeywordContext.Provider>
);

export default RedactByKeywordContextProvider;
